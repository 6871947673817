import {
  addDays,
  addMonths,
  addYears,
  differenceInDays,
  endOfDay,
  endOfISOWeek,
  endOfMonth,
  endOfYear,
  format,
  isSameDay,
  parse as parseDate,
  parseISO,
  set,
  startOfDay,
  startOfISOWeek,
  startOfMonth,
  startOfYear,
  subDays,
  subHours,
  subMonths,
  subSeconds,
  subWeeks,
  subYears,
} from 'date-fns';
import { parse, toSeconds } from 'iso8601-duration';

import { DurationType } from 'types/agenda.types';

export const APPLICATION_DATE_FORMAT = 'dd/MM/yyyy';
export const APPLICATION_TIME_FORMAT = 'HH:mm';
export const APPLICATION_DATETIME_FORMAT = 'dd/MM/yyyy - HH:mm';
export const UTC_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";
export const API_DATE_FORMAT = 'yyyy-MM-dd';
export const API_DATETIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";
export const API_TIME_FORMAT = 'HH:mm:ss';

export type DateRange = {
  type?: DateRangeType;
  startDate?: Date | null;
  endDate?: Date | null;
};

export type SerializableDateRange = {
  type?: DateRangeType;
  startDate: string | null;
  endDate: string | null;
};

export enum DateResolutions {
  Hour = 'HOUR',
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH',
  Year = 'YEAR',
}

export enum DateRangeType {
  TODAY = 'TODAY',
  TOMORROW = 'TOMORROW',
  YESTERDAY = 'YESTERDAY',
  DAY_BEFORE_YESTERDAY = 'DAY_BEFORE_YESTERDAY',
  THIS_WEEK = 'THIS_WEEK',
  LAST_WEEK = 'LAST_WEEK',
  WEEK_BEFORE_LAST_WEEK = 'WEEK_BEFORE_LAST_WEEK',
  THIS_MONTH = 'THIS_MONTH',
  LAST_MONTH = 'LAST_MONTH',
  MONTH_BEFORE_LAST_MONTH = 'MONTH_BEFORE_LAST_MONTH',
  THIS_YEAR = 'THIS_YEAR',
  LAST_YEAR = 'LAST_YEAR',
  PUBLISH_DATE = 'PUBLISH_DATE',
  ONE_MONTH_BEFORE = 'ONE_MONTH_BEFORE',
  ONE_DAY_BEFORE = 'ONE_DAY_BEFORE',
  ONE_YEAR_BEFORE = 'ONE_YEAR_BEFORE',
  ONE_WEEK_BEFORE = 'ONE_WEEK_BEFORE',
  LAST_24_HOURS = 'LAST_24_HOURS',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_28_DAYS = 'LAST_28_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_12_MONTHS = 'LAST_12_MONTHS',
  NEXT_7_DAYS = 'NEXT_7_DAYS',
  NEXT_MONTH = 'NEXT_MONTH',
  NEXT_YEAR = 'NEXT_YEAR',
  WHENEVER = 'WHENEVER',
  /** This custom type will set the hours of the start and end date to the beginning and ending of the day (00:00 and 23:59) */
  CUSTOM = 'CUSTOM',
  /** This custom type will respect the hours of the start and end date (used in scheduled saved searches for example) */
  CUSTOM_EXACT = 'CUSTOM_EXACT',
}

export const DAYS_OF_WEEK = {
  0: 'sunday',
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
};

export enum PeriodComparison {
  PREVIOUS_PERIOD_MATCH = 'PREVIOUS_PERIOD_MATCH',
  PREVIOUS_PERIOD = 'PREVIOUS_PERIOD',
  LAST_YEAR_MATCH = 'LAST_YEAR_MATCH',
  LAST_YEAR = 'LAST_YEAR',
}

export enum ComparisonType {
  PERIODS = 'PERIODS',
  DATASETS = 'DATASETS',
}

export const formatDate = ({ startDate, endDate }: DateRange, formatString: string) => ({
  start: startDate ? format(startDate, formatString) : undefined,
  end: endDate ? format(endDate, formatString) : undefined,
});

export const formatTimeToDate = (time?: string) => {
  if (!time) return;
  if (time.split(':').length === 2) {
    return parseDate(time, APPLICATION_TIME_FORMAT, new Date());
  } else {
    return parseDate(time, API_TIME_FORMAT, new Date());
  }
};

export const formatDateToApiParams = (dateRange: DateRange) =>
  formatDate(dateRange, API_DATE_FORMAT);

export const formatDateRangeToApiParams = (
  { type, startDate, endDate }: DateRange,
  withTime?: boolean,
) => {
  const formatString =
    type === DateRangeType.LAST_24_HOURS || withTime ? API_DATETIME_FORMAT : API_DATE_FORMAT;
  return formatDate({ startDate, endDate }, formatString);
};

export const serializeDateRange = ({ type, startDate, endDate }: DateRange) => ({
  type,
  startDate: startDate ? startDate.toUTCString() : null,
  endDate: endDate ? endDate.toUTCString() : null,
});

export const deserializeDateRange = ({ type, startDate, endDate }: SerializableDateRange) => ({
  type,
  startDate: startDate ? new Date(startDate) : null,
  endDate: endDate ? new Date(endDate) : null,
});

// Format date range to 'dd/MM/yyyy - dd/MM/yyyy' or 'dd/MM/yyyy' if start and end date are the same
export const formatDateRange = (startDate: string, endDate?: string) => {
  if (endDate && !isSameDay(parseISO(startDate), parseISO(endDate))) {
    return `${format(parseISO(startDate), APPLICATION_DATE_FORMAT)} - ${format(
      parseISO(endDate),
      APPLICATION_DATE_FORMAT,
    )}`;
  }
  return format(parseISO(startDate), APPLICATION_DATE_FORMAT);
};

export const formatDateToReadableString = (
  startDate: string,
  todayString: string,
  tomorrowString: string,
) => {
  const startDateTime = new Date(startDate);
  const today = startOfDay(new Date());
  const tomorrow = addDays(today, 1);

  if (isSameDay(startDateTime, today)) {
    return `${todayString}, ${format(startDateTime, APPLICATION_DATE_FORMAT)}`;
  }
  if (isSameDay(startDateTime, tomorrow)) {
    return `${tomorrowString}, ${format(startDateTime, APPLICATION_DATE_FORMAT)}`;
  }
  return format(startDateTime, APPLICATION_DATE_FORMAT);
};

export const formatTimeRange = (
  startTimeString?: string,
  endTimeString?: string,
  durationType?: DurationType,
  allDayString?: string,
) => {
  const startDateTime = !!startTimeString
    ? format(
        parseDate(
          startTimeString,
          startTimeString.length > 5 ? API_TIME_FORMAT : APPLICATION_TIME_FORMAT,
          new Date(),
        ),
        APPLICATION_TIME_FORMAT,
      )
    : undefined;
  const endDateTime = !!endTimeString
    ? format(
        parseDate(
          endTimeString,
          endTimeString.length > 5 ? API_TIME_FORMAT : APPLICATION_TIME_FORMAT,
          new Date(),
        ),
        APPLICATION_TIME_FORMAT,
      )
    : undefined;
  if (durationType === DurationType.ALL_DAY) {
    return allDayString;
  }

  if (durationType === DurationType.END_OF_DAY) {
    return `${startDateTime} - ...`;
  }

  if (startTimeString && endTimeString) {
    return `${startDateTime} - ${endDateTime}`;
  }

  if (startTimeString) {
    return startDateTime;
  }

  return '-';
};

export const formatTime = (timeString?: string) => {
  const startDateTime = !!timeString
    ? format(parseDate(timeString, API_TIME_FORMAT, new Date()), APPLICATION_TIME_FORMAT)
    : undefined;

  return startDateTime;
};

export const formatDateTimeIcs = (
  date: string,
  time?: string,
): [number, number, number, number, number] => {
  const dateParts = date.split('-').map(Number);
  const timeParts = time?.split(':').map(Number) || [];
  return [dateParts[0], dateParts[1], dateParts[2], ...timeParts] as [
    number,
    number,
    number,
    number,
    number,
  ];
};

/**
 * YEAR METHODS
 */

export function getThisYear(endDate?: Date | null) {
  const today = new Date();
  return {
    type: DateRangeType.THIS_YEAR,
    startDate: startOfYear(today),
    endDate: endDate || endOfYear(today),
  };
}

export function getLastYear() {
  const today = new Date();
  const lastYear = subYears(today, 1);

  return {
    type: DateRangeType.LAST_YEAR,
    startDate: startOfYear(lastYear),
    endDate: endOfYear(lastYear),
  };
}

export function getYearBeforeDate(date: Date) {
  const yearBeforeDate = subYears(date, 1);
  return {
    type: DateRangeType.ONE_YEAR_BEFORE,
    startDate: yearBeforeDate,
    endDate: date,
  };
}

export function getFromNowToNextYear() {
  const startDate = new Date();
  const endDate = addYears(new Date(), 1);
  return {
    startDate,
    endDate,
    type: DateRangeType.CUSTOM,
  };
}

/**
 * MONTH METHODS
 */

export function getThisMonth(endDate?: Date | null) {
  const today = new Date();
  return {
    type: DateRangeType.THIS_MONTH,
    startDate: startOfMonth(today),
    endDate: endDate || endOfMonth(today),
  };
}

export function getLastMonth() {
  const today = new Date();
  const lastMonth = subMonths(today, 1);

  return {
    type: DateRangeType.LAST_MONTH,
    startDate: startOfMonth(lastMonth),
    endDate: endOfMonth(lastMonth),
  };
}

export function getMonthBeforeLastMonth() {
  const today = new Date();
  const monthBeforeLast = subMonths(today, 2);

  return {
    type: DateRangeType.MONTH_BEFORE_LAST_MONTH,
    startDate: startOfMonth(monthBeforeLast),
    endDate: endOfMonth(monthBeforeLast),
  };
}

export function getMonthBeforeDate(date: Date) {
  const monthBeforeDate = subMonths(date, 1);

  return {
    type: DateRangeType.ONE_MONTH_BEFORE,
    startDate: monthBeforeDate,
    endDate: date,
  };
}

/**
 *  WEEK METHODS
 */

export function getThisWeek(endDate?: Date | null) {
  const today = new Date();
  return {
    type: DateRangeType.THIS_WEEK,
    startDate: startOfISOWeek(today),
    endDate: endDate || endOfISOWeek(today),
  };
}

export function getLastWeek() {
  const today = new Date();
  const lastWeek = subWeeks(today, 1);

  return {
    type: DateRangeType.LAST_WEEK,
    startDate: startOfISOWeek(lastWeek),
    endDate: endOfISOWeek(lastWeek),
  };
}

export function getWeekBeforeLastWeek() {
  const today = new Date();
  const weekBeforeLast = subWeeks(today, 2);

  return {
    type: DateRangeType.WEEK_BEFORE_LAST_WEEK,
    startDate: startOfISOWeek(weekBeforeLast),
    endDate: endOfISOWeek(weekBeforeLast),
  };
}

export function getWeekBeforeDate(date: Date) {
  const weekBeforeDate = subWeeks(date, 1);

  return {
    type: DateRangeType.ONE_WEEK_BEFORE,
    startDate: weekBeforeDate,
    endDate: date,
  };
}

/**
 * DAY METHODS
 */

export function getTomorrow() {
  const today = new Date();
  return {
    type: DateRangeType.TOMORROW,
    startDate: startOfDay(addDays(today, 1)),
    endDate: endOfDay(addDays(today, 1)),
  };
}

export function getToday() {
  const today = new Date();
  return {
    type: DateRangeType.TODAY,
    startDate: startOfDay(today),
    endDate: endOfDay(today),
  };
}

export function getYesterday() {
  const today = new Date();
  const yesterday = subDays(today, 1);

  return {
    type: DateRangeType.YESTERDAY,
    startDate: yesterday,
    endDate: yesterday,
  };
}

export function getDayBeforeYesterday() {
  const today = new Date();
  const dayBeforeYesterday = subDays(today, 2);

  return {
    type: DateRangeType.DAY_BEFORE_YESTERDAY,
    startDate: dayBeforeYesterday,
    endDate: subDays(today, 1),
  };
}

function getDatePeriod(date: Date) {
  return {
    type: DateRangeType.PUBLISH_DATE,
    startDate: date,
    endDate: date,
  };
}

export function getDayBeforeDate(date: Date) {
  const dayBeforeDate = subDays(date, 1);
  return {
    type: DateRangeType.ONE_DAY_BEFORE,
    startDate: dayBeforeDate,
    endDate: date,
  };
}

export function getWhenever() {
  return {
    type: DateRangeType.WHENEVER,
    startDate: null,
    endDate: null,
  };
}

export function getCustomDate(startDate: Date, endDate?: Date) {
  const end = endDate ? endDate : startDate;

  return {
    type: DateRangeType.CUSTOM,
    startDate: startDate,
    endDate: end,
  };
}

/**
 * LAST methods
 */
export function getLast24Hours(startDate?: Date | null) {
  const today = set(startDate || new Date(), { seconds: 59 });
  return {
    type: DateRangeType.LAST_24_HOURS,
    startDate: subHours(today, 24),
    endDate: today,
  };
}

export function getLast7Days(startDate?: Date | null) {
  const today = set(startDate || new Date(), { seconds: 59 });
  return {
    type: DateRangeType.LAST_7_DAYS,
    startDate: subDays(today, 7),
    endDate: today,
  };
}

export function getLast28Days(startDate?: Date | null) {
  const today = set(startDate || new Date(), { seconds: 59 });
  return {
    type: DateRangeType.LAST_28_DAYS,
    startDate: subDays(today, 28),
    endDate: today,
  };
}

export function getLast30Days(startDate?: Date | null) {
  const today = set(startDate || new Date(), { seconds: 59 });
  return {
    type: DateRangeType.LAST_30_DAYS,
    startDate: subDays(today, 30),
    endDate: today,
  };
}

export function getLast12Months(startDate?: Date | null) {
  const today = set(startDate || new Date(), { seconds: 59 });
  return {
    type: DateRangeType.LAST_12_MONTHS,
    startDate: subMonths(today, 12),
    endDate: today,
  };
}

/**
 * NEXT METHODS
 */

export function getNext7Days() {
  const startDate = startOfDay(new Date());
  return {
    type: DateRangeType.NEXT_7_DAYS,
    startDate,
    endDate: endOfDay(addDays(startDate, 7)),
  };
}

export function getNextMonth() {
  const monthStart = startOfMonth(addMonths(new Date(), 1));
  return {
    type: DateRangeType.NEXT_MONTH,
    startDate: monthStart,
    endDate: endOfMonth(monthStart),
  };
}
export function getNextYear() {
  const startDate = startOfYear(addYears(new Date(), 1));
  return {
    type: DateRangeType.NEXT_YEAR,
    startDate,
    endDate: endOfYear(startDate),
  };
}

/**
 * COMPARE METHODS
 */

export function getPeriodBeforeCustomPeriod(oldStartDate: Date, oldEndDate: Date) {
  const amountOfDays = differenceInDays(oldEndDate, oldStartDate) + 1;
  const newStartDate = subDays(oldStartDate, amountOfDays);
  const newEndDate = subDays(oldStartDate, 1);

  return {
    type: DateRangeType.CUSTOM,
    startDate: newStartDate,
    endDate: newEndDate,
  };
}

export function areDateRangesEqual(dateRange1: DateRange, dateRange2: DateRange) {
  const { type: type1, startDate: startDate1, endDate: endDate1 } = dateRange1;
  const { type: type2, startDate: startDate2, endDate: endDate2 } = dateRange2;

  if (type1 === type2 && type1 !== DateRangeType.CUSTOM) return true;
  if (!startDate1 || !startDate2 || !endDate1 || !endDate2) return false;

  if (isSameDay(startDate1, startDate2) && isSameDay(endDate1, endDate2)) return true;

  return false;
}

export function getTypeForDateRange(dateRange?: DateRange) {
  if (dateRange) {
    if (areDateRangesEqual(dateRange, getToday())) return DateRangeType.TODAY;
    if (areDateRangesEqual(dateRange, getYesterday())) return DateRangeType.YESTERDAY;

    if (areDateRangesEqual(dateRange, getThisWeek())) return DateRangeType.THIS_WEEK;
    if (areDateRangesEqual(dateRange, getLastWeek())) return DateRangeType.LAST_WEEK;

    if (areDateRangesEqual(dateRange, getThisMonth())) return DateRangeType.THIS_MONTH;
    if (areDateRangesEqual(dateRange, getLastMonth())) return DateRangeType.LAST_MONTH;

    if (areDateRangesEqual(dateRange, getThisYear())) return DateRangeType.THIS_YEAR;
    if (areDateRangesEqual(dateRange, getLastYear())) return DateRangeType.LAST_YEAR;
  }

  return DateRangeType.CUSTOM;
}

export const getDateRangeForType = (type: DateRangeType, date?: Date | null) => {
  switch (type) {
    case DateRangeType.LAST_24_HOURS:
      return getLast24Hours();

    case DateRangeType.TODAY:
      return getToday();

    case DateRangeType.YESTERDAY:
      return getYesterday();

    case DateRangeType.DAY_BEFORE_YESTERDAY:
      return getDayBeforeYesterday();

    case DateRangeType.THIS_WEEK:
      return getThisWeek(date);

    case DateRangeType.LAST_WEEK:
      return getLastWeek();

    case DateRangeType.WEEK_BEFORE_LAST_WEEK:
      return getWeekBeforeLastWeek();

    case DateRangeType.THIS_MONTH:
      return getThisMonth(date);

    case DateRangeType.LAST_MONTH:
      return getLastMonth();

    case DateRangeType.MONTH_BEFORE_LAST_MONTH:
      return getMonthBeforeLastMonth();

    case DateRangeType.THIS_YEAR:
      return getThisYear(date);

    case DateRangeType.LAST_YEAR:
      return getLastYear();

    case DateRangeType.PUBLISH_DATE:
      return date ? getDatePeriod(date) : null;

    case DateRangeType.ONE_DAY_BEFORE:
      return date ? getDayBeforeDate(date) : null;

    case DateRangeType.ONE_WEEK_BEFORE:
      return date ? getWeekBeforeDate(date) : null;

    case DateRangeType.ONE_MONTH_BEFORE:
      return date ? getMonthBeforeDate(date) : null;

    case DateRangeType.ONE_YEAR_BEFORE:
      return date ? getYearBeforeDate(date) : null;

    case DateRangeType.WHENEVER:
      return getWhenever();

    case DateRangeType.LAST_7_DAYS:
      return getLast7Days();

    case DateRangeType.LAST_28_DAYS:
      return getLast28Days();

    case DateRangeType.LAST_30_DAYS:
      return getLast30Days();

    case DateRangeType.LAST_12_MONTHS:
      return getLast12Months();

    case DateRangeType.CUSTOM:
      return null;
    case DateRangeType.CUSTOM_EXACT:
      return null;

    case DateRangeType.TOMORROW:
      return getTomorrow();
    case DateRangeType.NEXT_7_DAYS:
      return getNext7Days();
    case DateRangeType.NEXT_MONTH:
      return getNextMonth();
    case DateRangeType.NEXT_YEAR:
      return getNextYear();

    default:
      // Typescript will start screaming when a DateRangeType isn't covered in the switch
      const shouldBeTypeNever: never = type;
      console.error(shouldBeTypeNever, 'does not have a daterange configured');
      return null;
  }
};

export const isValidDate = (date: unknown): date is Date =>
  date instanceof Date && !isNaN(date.valueOf());

export const getRelativeTime = (date?: Date | string | null) => {
  if (!date) return undefined;
  const parsedDate = isValidDate(date) ? date : parseISO(date);
  return (parsedDate.getTime() - new Date().getTime()) / 1000;
};

export const durationFormatString = (duration: number) => (duration >= 3600 ? 'HH:mm:ss' : 'mm:ss');

// Takes in seconds, converts it to 04:30 or 01:20:30
export const formatSeconds = (seconds: number) =>
  format(secondsToDate(seconds), durationFormatString(seconds));

export function secondsToDate(seconds: number) {
  const epoch = new Date(0);
  const timeZoneOffset = epoch.getTimezoneOffset() * 60;
  epoch.setUTCSeconds(seconds + timeZoneOffset);

  return epoch;
}

export const maxDateForEmbargo = (duration?: string | null) => {
  if (!duration) return new Date();
  const maxDate = new Date();
  const seconds = toSeconds(parse(duration));
  return subSeconds(maxDate, seconds);
};

// Attempt to return a sensible default period based on the given dateRange and
// maxDate, use to ensure embargo users don't see an impossible selection in the datepicker
// TODO: If there are any other defaults used in the wild we will need to expand this to include them
export const getDefaultDateRangePeriod = (
  dateRange: DateRangeType = DateRangeType.LAST_24_HOURS,
  maxDate?: Date | null,
  embargo?: boolean,
) => {
  // For TODAY fall back to a custom date depending on max date
  if (maxDate && embargo && dateRange === DateRangeType.TODAY) {
    return getCustomDate(maxDate, maxDate);
    // For LAST_24_HOURS fallback to last 24h starting from max date
  } else if (maxDate && embargo && dateRange === DateRangeType.LAST_24_HOURS) {
    return { ...getLast24Hours(maxDate), type: DateRangeType.CUSTOM };
    // Attempt to resolve the default range type
  } else {
    return getDateRangeForType(dateRange, maxDate)!;
  }
};

export const castDateRangeType = (type?: DateRangeType | string): DateRangeType | undefined => {
  if (type) {
    return type.toUpperCase() as DateRangeType;
  }
};

export const occurrenceRangeToApiParams = (date?: Date) =>
  date
    ? {
        from: format(startOfMonth(date), API_DATE_FORMAT),
        to: format(endOfMonth(date), API_DATE_FORMAT),
      }
    : {};

export const formatISO = (isoString?: string, dateFormat = APPLICATION_DATETIME_FORMAT) => {
  if (!isoString) return '';
  return format(parseISO(isoString), dateFormat);
};

export const inferNextComparisonDaterange = ({
  dateRange,
  periodComparison,
}: {
  dateRange: DateRange;
  periodComparison: PeriodComparison | null;
}) => {
  if (dateRange.startDate && dateRange.endDate) {
    let startDate = new Date(dateRange.startDate);
    let endDate = new Date(dateRange.endDate);

    switch (periodComparison) {
      case PeriodComparison.LAST_YEAR:
        startDate = subYears(startDate, 1);
        endDate = subYears(endDate, 1);
        break;
      case PeriodComparison.LAST_YEAR_MATCH:
        startDate = subWeeks(startDate, 52);
        endDate = subWeeks(endDate, 52);
        break;
      case PeriodComparison.PREVIOUS_PERIOD:
        const difference = differenceInDays(endDate, startDate) + 1;
        startDate = subDays(startDate, difference);
        endDate = subDays(endDate, difference);
        break;
      case PeriodComparison.PREVIOUS_PERIOD_MATCH:
        if (endDate >= new Date(dateRange.startDate)) {
          startDate = subWeeks(startDate, 1);
          endDate = subWeeks(endDate, 1);
        }
        break;
    }

    return {
      type: dateRange.type,
      startDate,
      endDate,
    } as DateRange;
  } else {
    return { type: dateRange.type, startDate: null, endDate: null } as DateRange;
  }
};
